
import consumer from "./consumer"

(() => {
  let commentsListenerCreated = false

  document.addEventListener("turbolinks:load", setupCommentsChannels);

  function setupCommentsChannels() {
    const isLiveComments = document.querySelector('[data-live-comments]')

    if (isLiveComments && !commentsListenerCreated) {
      
      consumer.subscriptions.create("RecordCommentsChannel", {
        connected() {
          // Called when the subscription is ready for use on the server
          // console.log("Comments Listener Connected")
          commentsListenerCreated = true
        },
      
        disconnected() {
          // Called when the subscription has been terminated by the server
          // console.log("Comments Listener Disconnected")
          commentsListenerCreated = false
        },
      
        received(data) {
        // console.log(`comments_${project} Recieved Data`)
          let commentLog = document.querySelector(`[data-live-comments="${data.record_type}-${data.record}"] [data-comment-container]`)
          if (commentLog) {
            var parser = new DOMParser();
            var frag = parser.parseFromString(data.html, 'text/html').body.firstElementChild;
            if (frag.dataset.user != isLiveComments.dataset.user ) {
              // console.log(frag)
              frag.classList.remove('items-end')
              frag.classList.add('items-start')
              frag.firstElementChild.classList.remove('bg-[#EFF9EB]')
              frag.firstElementChild.classList.add('bg-gray-100')
              commentLog.append(frag)
            } else {
              commentLog.insertAdjacentHTML("beforeend", data.html)
            }          
            document.dispatchEvent(new CustomEvent('partialReRendered'))
            document.dispatchEvent(new CustomEvent('newComment', {detail: {for:`Comment-${data.record_type}-${data.record}`}}))
            commentLog.scrollTo(0, commentLog.scrollHeight);

          }
          // Called when there's incoming data on the websocket for this channel
        }
      })
    }
  }
})()

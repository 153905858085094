import consumer from "./consumer"

(() => {
  let clicksListenerCreated = false

  document.addEventListener("turbolinks:load", setupClicksChannels);

  function setupClicksChannels() {
    const isLiveClicks = document.querySelector('[data-click-counter]')

    if (isLiveClicks && !clicksListenerCreated) {
      consumer.subscriptions.create("ClicksChannel", {
        connected() {
          // Called when the subscription is ready for use on the server
          // console.log("Clicks Listener Connected")
          clicksListenerCreated = true
        },
      
        disconnected() {
          // Called when the subscription has been terminated by the server
          // console.log("Clicks Listener Disconnected")
          clicksListenerCreated = false
        },
      
        received(data) {
          const matchingRecord = document.querySelector(`[data-click-record="${data.record_id}"][data-click-model="${data.record_type}"]`)
          if (matchingRecord) {
            if (data.clicks > 1) {
              matchingRecord.innerHTML = `${data.clicks} Clicks`
            } else {
              matchingRecord.innerHTML = `${data.clicks} Click`
            }
          }
          // Called when there's incoming data on the websocket for this channel
        }
      })
    }
  }
})()

document.addEventListener("turbolinks:load", () => {
  const TRANSITION_DURATION = 300;
  const mobileXMenus = document.querySelectorAll("[data-mobile-menu-slide-x]");
  const mobileYMenus = document.querySelectorAll("[data-mobile-menu-slide-y]");
  if (mobileXMenus) {
    const mobileMenuOpeners = document.querySelectorAll(
      "[data-mobile-menu-opener]"
    );

    mobileXMenus.forEach((mobileMenu) => {
      const slideLeft = "slideLeft" in mobileMenu.dataset ? true : false;
      const menuClosers = mobileMenu.querySelectorAll("[data-menu-closer]");
      const menuOverlays = mobileMenu.querySelectorAll("[data-menu-overlay]");
      const menuCanvasses = mobileMenu.querySelectorAll("[data-menu-canvas]");
      menuClosers.forEach((closer) => {
        closer.addEventListener("click", () =>
          mobileMenu.dispatchEvent(new CustomEvent("mobile-menu-close"))
        );
      });
      menuOverlays.forEach((overlay) => {
        overlay.addEventListener("click", () =>
          mobileMenu.dispatchEvent(new CustomEvent("mobile-menu-close"))
        );
      });
      mobileMenu.addEventListener("mobile-menu-close", function () {
        menuCanvasses.forEach((canvas) => {
          if (slideLeft) {
            canvas.classList.remove("translate-x-0");
            canvas.classList.add("translate-x-full");
          } else {
            canvas.classList.remove("translate-x-0");
            canvas.classList.add("-translate-x-full");
          }
        });
        menuClosers.forEach((closer) => {
          closer.classList.remove("opacity-100");
          closer.classList.add("opacity-0");
        });
        menuOverlays.forEach((overlay) => {
          overlay.classList.remove("opacity-100");
          overlay.classList.add("opacity-0");
        });
        setTimeout(() => {
          this.classList.remove("z-40");
          this.classList.add("-z-10");
        }, TRANSITION_DURATION);
      });
    });
    mobileMenuOpeners.forEach((opener) => {
      const menuWrapper = opener.closest("[data-mobile-menu-container]");
      if (menuWrapper) {
        const wrappedXMenus = menuWrapper.querySelectorAll(
          "[data-mobile-menu-slide-x]"
        );

        wrappedXMenus.forEach((mobileMenu) => {
          const slideLeft = "slideLeft" in mobileMenu.dataset ? true : false;
          const menuClosers = mobileMenu.querySelectorAll("[data-menu-closer]");
          const menuOverlays = mobileMenu.querySelectorAll(
            "[data-menu-overlay]"
          );
          const menuCanvasses =
            mobileMenu.querySelectorAll("[data-menu-canvas]");
          opener.addEventListener("click", () => {
            mobileMenu.dispatchEvent(new CustomEvent("mobile-menu-open"));
          });
          mobileMenu.addEventListener("mobile-menu-open", function () {
            menuCanvasses.forEach((canvas) => {
              if (slideLeft) {
                canvas.classList.add("translate-x-0");
                canvas.classList.remove("translate-x-full");
              } else {
                canvas.classList.add("translate-x-0");
                canvas.classList.remove("-translate-x-full");
              }
            });
            menuClosers.forEach((closer) => {
              closer.classList.remove("opacity-0");
              closer.classList.add("opacity-100");
            });
            menuOverlays.forEach((overlay) => {
              overlay.classList.remove("opacity-0");
              overlay.classList.add("opacity-100");
            });
            this.classList.remove("-z-10");
            this.classList.add("z-40");
          });
        });
      }
    });
  }

  if (mobileYMenus) {
    const mobileMenuOpeners = document.querySelectorAll(
      "[data-mobile-menu-opener]"
    );

    mobileMenuOpeners.forEach((opener) => {
      const menuWrapper = opener.closest("[data-mobile-menu-container]");
      if (menuWrapper) {
        const wrappedYMenus = menuWrapper.querySelectorAll(
          "[data-mobile-menu-slide-Y]"
        );
        const menuClosers = menuWrapper.querySelectorAll(
          "[data-mobile-menu-closer]"
        );
        wrappedYMenus.forEach((mobileMenu) => {
          opener.addEventListener("click", () => {
            mobileMenu.dispatchEvent(new CustomEvent("mobile-menu-open"));
          });
          mobileMenu.addEventListener("mobile-menu-open", function () {
            mobileMenuOpeners.forEach((opener) => {
              opener.classList.remove("block");
              opener.classList.add("hidden");
            });
            menuClosers.forEach((closer) => {
              closer.classList.remove("hidden");
              closer.classList.add("block");
            });
            this.classList.add("translate-y-0");
            this.classList.remove("-translate-y-full");
          });
        });
      }
    });
    mobileYMenus.forEach((mobileMenu) => {
      const menuWrapper = mobileMenu.closest("[data-mobile-menu-container]");
      if (menuWrapper) {
        const menuClosers = menuWrapper.querySelectorAll(
          "[data-mobile-menu-closer]"
        );
        menuClosers.forEach((closer) => {
          closer.addEventListener("click", () =>
            mobileMenu.dispatchEvent(new CustomEvent("mobile-menu-close"))
          );
        });
        mobileMenu.addEventListener("mobile-menu-close", function () {
          menuClosers.forEach((closer) => {
            closer.classList.remove("block");
            closer.classList.add("hidden");
          });
          mobileMenuOpeners.forEach((opener) => {
            opener.classList.remove("hidden");
            opener.classList.add("block");
          });
          this.classList.remove("translate-y-0");
          this.classList.add("-translate-y-full");
        });
      }
    });
  }
});

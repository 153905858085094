document.addEventListener("turbolinks:load", () => {
  const MESSAGE_SHOW_DURATION = 10000;
  const MESSAGE_DELAY = 2000;
  const REMOVE_DELAY = 300;
  const CLOSED_MESSAGE_CLASS = "flash-message-closed";

  const flashContainers = document.querySelectorAll("[data-flash-container]");

  flashContainers.forEach((container) => {
    const containerMessages = container.querySelectorAll(
      "[data-flash-message]"
    );
    container.messageCount = containerMessages.length;
    container.closedCount = 0;

    container.addEventListener("message-closed", () => {
      container.closedCount += 1;
      if (container.closedCount >= container.messageCount) {
        setTimeout(() => {
          container.remove();
        }, REMOVE_DELAY);
      }
    });

    containerMessages.forEach((message, index) => {
      toggleMessageVisibility(message, true);
      message.style.maxHeight = "fit-content";
      message.addEventListener("message-close", () => {
        toggleMessageVisibility(message, false);
        container.dispatchEvent(new CustomEvent("message-closed"));
      });
      const messageCloser = message.querySelector("[data-flash-closer]");
      messageCloser.addEventListener("click", () => {
        message.dispatchEvent(new CustomEvent("message-close"));
      });
      setTimeout(() => {
        if (!message.classList.contains(CLOSED_MESSAGE_CLASS)) {
          toggleMessageVisibility(message, false);
          message.dispatchEvent(new CustomEvent("message-close"));
        }
      }, MESSAGE_SHOW_DURATION + index * MESSAGE_DELAY);
    });
  });

  function toggleMessageVisibility(message, visible = false) {
    if (visible) {
      message.classList.remove(
        "translate-y-2",
        "opacity-0",
        "sm:translate-y-0",
        "sm:translate-x-2"
      );
      message.classList.add("translate-y-0", "opacity-100", "sm:translate-x-0");
    } else {
      message.classList.remove(
        "translate-y-0",
        "opacity-100",
        "sm:translate-x-0"
      );
      message.classList.add(
        "translate-y-2",
        "opacity-0",
        "sm:translate-y-0",
        "sm:translate-x-2",
        "overflow-hidden",
        CLOSED_MESSAGE_CLASS
      );
      setTimeout(() => {
        message.style.transition = "max-height 0.3s ease-out";
        message.style.maxHeight = "0";
      }, REMOVE_DELAY);
    }
  }
});

const { sort } = require("webpack/lib/dependencies/DependencyReference")

document.addEventListener("turbolinks:load", () => {
  const TRANSITION_DURATION = 300
  const MAX_TEXT_HEIGHT = 200
  const commentEntryBoxes = document.querySelectorAll('[data-comment-entry]')
  const commentLogs = document.querySelectorAll(`[data-live-comments] [data-comment-container]`)
  const auditLogs = document.querySelectorAll(`[data-live-audits]`)
  const commentNotifications = document.querySelectorAll('[data-tab-notification]')

  const updateModal = document.querySelector('[data-warning-modal]')
  commentEntryBoxes.forEach((entryBox) => {
    if (entryBox.value) {
      entryBox.value = ''
    }
    entryBox.style.boxSizing = 'border-box';
    entryBox.addEventListener('keyup', function (event) {
      if (event.key === 'Enter') {
        entryBox.closest('form').querySelector('button').click()
      } else {
        event.target.style.height = 'auto';
        event.target.style.height = `${event.target.scrollHeight < MAX_TEXT_HEIGHT ? event.target.scrollHeight : MAX_TEXT_HEIGHT}px`;
      }
      
    });
  })
  
  commentLogs.forEach((commentLog) => {
    document.addEventListener('tab-changed', () => {
      commentLog.scrollTo(0, commentLog.scrollHeight);
    })
    commentLog.scrollTo(0, commentLog.scrollHeight);
  })

  commentNotifications.forEach((notification) => {
    document.addEventListener('newComment', ({detail}) => {
      const trigger = notification.closest('[data-tab-trigger]')
      if (trigger?.dataset.active != 'true' && detail.for === notification.dataset.tabNotification) {
        if (notification.classList.contains('opacity-0')) {
          notification.classList.remove('opacity-0')
        }
        notification.closest('[data-tab-trigger]')
      }
    })
    const parentTab = notification.closest('[data-tab-for]')
    if (parentTab) {
      parentTab.addEventListener('click', () => {
        if (!notification.classList.contains('opacity-0')) {
          notification.classList.add('opacity-0')
        }
      })
    }
  })

  auditLogs.forEach((auditLog) => {
    document.addEventListener('tab-changed', () => {
      auditLog.scrollTo(0, auditLog.scrollHeight);
    })
    auditLog.scrollTo(0, auditLog.scrollHeight);
  })

  if (updateModal) {
    const overlay = updateModal.querySelector("[data-modal-overlay]");
    const modal = updateModal.querySelector("[data-modal]");
    const modalCloser = updateModal.querySelector("[data-cancel]");
     const modalAccepted = updateModal.querySelector('[data-confirm]')
    const closeModal = () => {
      overlay.classList.remove("opacity-100");
      overlay.classList.add("opacity-0");
      modal.classList.add(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.remove("opacity-100", "translate-y-0", "sm:scale-100");
      setTimeout(() => {
        updateModal.classList.remove("z-40");
        updateModal.classList.add("-z-10");
      }, TRANSITION_DURATION);
    };
    overlay.addEventListener("click", (e) => {
      closeModal();
    });

    modalCloser.addEventListener("click", (e) => {
      closeModal();
    });
    modalAccepted.addEventListener('click', (e) => {
      e.preventDefault()
      window.location.reload()
    })

    document.addEventListener('pageUpdated', () => {
        updateModal.classList.remove("-z-10");
        updateModal.classList.add("z-40");
        overlay.classList.remove("opacity-0");
        overlay.classList.add("opacity-100");
        modal.classList.remove(
          "opacity-0",
          "translate-y-4",
          "sm:translate-y-0",
          "sm:scale-95"
        );
        modal.classList.add("opacity-100", "translate-y-0", "sm:scale-100");
    })
  }
})
import consumer from "./consumer"

(() => {
  let loginListenerCreated = false

  document.addEventListener("turbolinks:load", setupLoginChannels);

  function setupLoginChannels() {
    const isLiveComments = document.querySelector('[data-user-session-status]')

    if (isLiveComments && !loginListenerCreated) {
      consumer.subscriptions.create("LoginChannel", {
        connected() {
          // Called when the subscription is ready for use on the server
          // console.log("Login Listener Connected")
          loginListenerCreated = true
        },
      
        disconnected() {
          // Called when the subscription has been terminated by the server
          // console.log("Login Listener Disconnected")
          loginListenerCreated = false
        },
      
        received(data) {
          // console.log(data)
          const user = document.querySelector(`[data-user-session-status][data-user-session-user="${data.user}"]`)
          if (user) {
            const onlineIndicator = user.querySelector('[data-active-state]')
            const offlineIndicator = user.querySelector('[data-inactive-state]')
            if (data.logged_in) {
              if (onlineIndicator.classList.contains('hidden')) {
                onlineIndicator.classList.remove('hidden')
              }
              if (!offlineIndicator.classList.contains('hidden')) {
                offlineIndicator.classList.add('hidden')
              }
            } else {
              if (!onlineIndicator.classList.contains('hidden')) {
                onlineIndicator.classList.add('hidden')
              }
              if (offlineIndicator.classList.contains('hidden')) {
                offlineIndicator.classList.remove('hidden')
              }
            }
          }
          // Called when there's incoming data on the websocket for this channel
        }
      })
    }
  }
})()

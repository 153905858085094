document.addEventListener("turbolinks:load", () => {
  
  maybeLocalizeTimestamps()
  const tabbedData = document.querySelectorAll("[data-tabbed-data]");


  tabbedData.forEach((dataSet) => {
    const activeTriggerClasses = ["border-b-0", "bg-white", "text-black", 'td-trigger-active'];
    const nonActiveTriggerClasses = [
      "bg-gray-50",
      "text-gray-500",
      "hover:text-gray-700",
      "hover:border-td-primary",
    ];

    let triggerTags = dataSet.querySelectorAll("a[data-tab-trigger]");
    let triggerSelects = dataSet.querySelectorAll("select[data-tab-trigger]");
    let tabPages = dataSet.querySelectorAll("[data-tab]");
    const hasNested = !!dataSet.querySelector('[data-tabbed-data]')
    if (hasNested) {
      triggerTags = dataSet.querySelectorAll("a[data-tab-trigger]:not([data-tab] [data-tabbed-data] a[data-tab-trigger])");
      triggerSelects = dataSet.querySelectorAll("select[data-tab-trigger]:not([data-tab] [data-tabbed-data] select[data-tab-trigger])");
      tabPages = dataSet.querySelectorAll("[data-tab]:not([data-tab] [data-tabbed-data] [data-tab])");
    }

    const updateTabs = (tabFor) => {
      tabPages.forEach((tab) => {
        if (tab.hasAttribute(`data-tab--${tabFor}`)) {
          tab.classList.remove("hidden");
        } else if (!tab.classList.contains("hidden")) {
          tab.classList.add("hidden");
        }
      });
      triggerTags.forEach((trigger) => {
        if (trigger.dataset.tabFor === tabFor) {
          trigger.classList.add(...activeTriggerClasses);
          trigger.classList.remove(...nonActiveTriggerClasses);
          trigger.setAttribute('data-active','true')
          document.dispatchEvent(
            new CustomEvent("tab-changed", { detail: { tab: tabFor } })
          );
          if (!dataSet.dataset.hasOwnProperty('supressUrl')) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("page", tabFor);
            const pagePath =
              window.location.origin +
              window.location.pathname +
              "?" +
              searchParams.toString();
            history.replaceState(history.state, "", pagePath);
          }
         
        } else {
          trigger.setAttribute('data-active','false')
          trigger.classList.remove(...activeTriggerClasses);
          trigger.classList.add(...nonActiveTriggerClasses);
        }
      });
    };

    const windowParams = new URLSearchParams(window.location.search);
    if (windowParams.has("page") && !dataSet.dataset.hasOwnProperty('supressUrl')) {
      updateTabs(windowParams.get("page"));
      triggerSelects.forEach((select) => {
        const matching = select.querySelector(`[data-tab-for="${windowParams.get("page")}"]`);
        if (matching) {
          select.querySelectorAll("option").forEach((option) => {
            if (option == matching) {
              select.value=matching.value
              matching.setAttribute("selected","selected")
            } else {
              option.removeAttribute("selected")
            }
          })
          
        }
      })
    }

    triggerSelects.forEach((select) => {
      select.addEventListener("change", (e) => {
        const newValue = e.target.value;
        select.querySelectorAll('option').forEach((option) => {
          option.removeAttribute("selected")
        })
        const option = select.querySelector(`[value="${newValue}"]`);
        option.setAttribute("selected","selected")
        if (!dataSet.dataset.hasOwnProperty('supressUrl')) {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set("page", option.dataset.tabFor);
          const pagePath =
            window.location.origin +
            window.location.pathname +
            "?" +
            searchParams.toString();
          history.replaceState(history.state, "", pagePath);
        }

        updateTabs(option.dataset.tabFor);
      });
    });

    triggerTags.forEach((tag) => {
      tag.addEventListener("click", (e) => {
        e.preventDefault();
        updateTabs(tag.dataset.tabFor);
      });
    });
  });
})

document.addEventListener("ajax:success", () => {
  maybeLocalizeTimestamps()
});

document.addEventListener('upload-created', () => {
  maybeLocalizeTimestamps()
})

document.addEventListener("partialReRendered", () => {
  maybeLocalizeTimestamps()
});

function localizeTime(timeElm) {
  const tsString = timeElm.getAttribute("datetime");
  if (tsString) {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }
    if (timeElm.dataset.hasOwnProperty('includeTime')) {
      options.hour = "numeric"
      options.minute = "2-digit"
    }
    timeElm.innerText = new Date(tsString)
      .toLocaleString([],options)
      .replace(/-/g, "/")
      .toUpperCase();
  }
}

function maybeLocalizeTimestamps() {
  const timeStamps = document.querySelectorAll("[data-localize-time]");
  timeStamps.forEach((time) => {
    localizeTime(time);
  });
}
import consumer from "./consumer"


document.addEventListener("turbolinks:load", setupAuditChannels);


function setupAuditChannels () {
  // console.log("Beginning setting up Audit Channels")

  const isLiveAudits = document.querySelector('[data-live-audits]')
  if (isLiveAudits) {

    consumer.subscriptions.create("AuditsChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        // console.log("Audits Listener Connected")
      },
      received(data) {

        // console.log(`audits_u Recieved Data`)
        // Don't need to worry about validating because users unable to see this wouldn't find it
        const auditLog = document.querySelector(`[data-live-audits="${data.record_type}-${data.record}"]`)
        if (auditLog) {
          // console.log(`audits_u Found Valid Log for Upload ${data.upload}`)
          document.dispatchEvent(new CustomEvent('pageUpdated'))
        }
      },
      disconnected() {
        // console.log(`audits_u disconnected`)
      },
    })

  } else {
    // console.log("No Audit Channel to Initiate")
  }
 
}
